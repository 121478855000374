<template>
  <div class="d-flex flex-column full-height overflow-hidden">
    <document-toolbar
      class="mt-4"
      :delete-document="doDelete"
      :revert="revert"
      :save="saveDocument"
      :show-cancel="!preview"
      :show-delete="canDelete"
      :show-save="showSave"
      :special-actions="actions"
      :tab="tab"
      no-history
    />
    <v-form
      ref="form"
      @input="validateForm"
      v-model="form"
      class="d-flex flex-column full-height overflow-hidden"
    >
      <div class="px-6 flex-grow-1 overflow-y-auto">
        <translations
          v-if="!preview"
          :label="$t('t.Name')"
          :translations.sync="document.name"
          :isNew="documentIsNew"
          :rules="required"
        />
        <v-list>
          <draggable
            v-model="document.columns"
            draggable=".draggable"
            @start="closePicker"
            @end="updateSetLive"
          >
            <v-list-item
              v-for="(colArg, index) in document.columns"
              :key="index"
              class="draggable px-0"
            >
              <v-list-item-action class="my-0">
                <v-icon>{{$icon('i.Menu')}}</v-icon>
              </v-list-item-action>
              <v-list-item-content class="overflow-visible">
                <column-picker
                  bottom-element-selector="#panel-footer"
                  :clearable="false"
                  :selected-col-id.sync="colArg[0].col"
                  :consolidated.sync="colArg[0].consolidated"
                  :excluded-items="excludedColumns"
                  hide-currency
                  :filters="{'document-types': cameleonDocType}"
                  :rules="required"
                  @picker-loaded="addedColumn(colArg[0].col)"
                  @update:selected-col-id="updateSetLive"
                  @update:consolidated="updateSetLive"
                  ref="columns"
                >
                  <template v-slot:append>
                    <v-btn
                      icon
                      @click.stop="toggleOrder(index)"
                      @mouseup.stop
                    >
                      <v-icon
                        fab
                        small
                      >{{$icon(document.columns[index][1] === 'a' ? 'i.SortAsc' : 'i.SortDesc')}}</v-icon>
                    </v-btn>
                  </template>
                </column-picker>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  icon
                  @click="removeColumn(index)"
                >
                  <v-icon color="red">{{$icon('i.Close')}}</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </draggable>
        </v-list>
      </div>
      <div
        id="panel-footer"
        class="d-flex flex-column"
      >
        <div class="d-flex justify-center px-6 pb-6">
          <v-btn
            class="border-radius"
            @click="addColumn()"
            text
            color="primary"
            :disabled="hasEmptyColumn"
          >
            <v-icon class="ml-n2 mr-1">{{$icon('i.PlusCircle')}}</v-icon>{{$t('t.AddColumn')}}
          </v-btn>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
import DocPerm from '@/mixins/doc-perm'
import { GlobalSearch } from '@/pages/search/controllers'
import Preset from './preset'
import sidePanelController from '@/sidePanelController'

export default {
  mixins: [DocPerm, Preset],
  components: {
    ColumnPicker: () => import('@/components/column-picker'),
    DocumentToolbar: () => import('@/components/document-toolbar'),
    Draggable: () => import('vuedraggable'),
    Translations: () => import('@/components/translations')
  },
  beforeDestroy () {
    const search = GlobalSearch.getActiveGridSearch(this.params.scope, this.params.targetDocTy)
    if (search) {
      if (this.isPristine && this.hasBeenSaved) {
        search.setSortSet(this.documentId)
      } else {
        search.setSortSet(undefined, true).execute()
      }
    }
  },
  computed: {
    actions () {
      const actions = []
      if (this.id) {
        if (this.docPerm.canCreate) {
          actions.push({
            text: this.$t('t.Duplicate'),
            icon: this.$icon('i.Templates'),
            callback: this.duplicate
          })
        }

        if (!(this.document?.isPublic ?? true) && (this.document?.canPublish ?? false)) {
          actions.push({
            text: this.$t('t.MakePublic'),
            icon: this.$icon('i.Eye'),
            callback: this.publish
          })
        }
      }
      return actions.length ? actions : undefined
    },
    cacheType () {
      return Preset.CacheType.SortSetDetail
    },
    canDelete () {
      return !this.preview && !this.documentIsNew && this.document.canDelete
    },
    showSave () {
      return !this.preview && (this.documentIsNew ? this.docPerm.canCreate : (this.document?.canWrite ?? true))
    },
    canSave () {
      return this.form
    },
    culture () {
      return this.$store.getters.currentUser.culture
    },
    docPermDocType () {
      return 'sort-sets'
    },
    excludedColumns () {
      return this.document.columns?.map(c => c[0]?.col).filter(id => id)
    },
    hasEmptyColumn () {
      return this.document.columns?.some(c => !c[0]?.col)
    },
    title () {
      return this.document.name?.[this.$store.getters.currentUser.culture] ?? this.$t('t.SortSettings')
    }
  },
  data () {
    return {
      init: false
    }
  },
  methods: {
    async refreshDocumentHook () {
      if (this.documentIsNew) {
        const search = GlobalSearch.getActiveGridSearch(this.params.scope, this.params.targetDocTy)

        const columns = this.params?.columns ?? search?.sorts.sortBy.map((col, index) =>
          [
            { col: search.columns[Number(col.slice(1))].id },
            search?.sorts.sortDesc[index] ? 'd' : 'a'
          ]
        ).filter(p => p[0]?.col) ?? []

        this.document = Object.assign(
          {},
          this.document,
          {
            id: this.documentId,
            columns,
            name: undefined,
            viewType: this.params.targetDocTy
          }
        )

        if (!this.document.columns.length) {
          this.addColumn()
        }

        this.init = true
        this.validateForm()
      }

      this.init = true
      this.updateSetLive()
      this.validateForm()
    },
    addColumn () {
      // col must be set to null and not undefined to prevent a visual duplication with drag and drop
      this.document.columns.push([{ col: null }, 'a'])
    },
    async doDelete () {
      await this.$http().delete(`/core/v6/cameleon/sort-sets/${this.document.id}`)
      this.close()
    },
    duplicate () {
      sidePanelController.displayDocument(null, 'sort-sets', { targetDocTy: this.params?.targetDocTy, scope: this.params?.scope, columns: this.document.columns })
    },
    publish () {
      this.document.isPublic = true
    },
    toggleOrder (index) {
      const columns = this.document.columns
      if (columns[index][1] === 'a') {
        columns[index][1] = 'd'
      } else {
        columns[index][1] = 'a'
      }
      this.document = Object.assign({}, this.document, { columns })
      this.updateSetLive()
    },
    // This method is called from the mixin preset.vue
    updateSetLive (doNotApply = true) {
      if (!this.init) { return }
      const columns = this.document?.columns?.filter(([c]) => c.col) ?? []
      const [
        sortBy = [],
        sortDesc = []
      ] = this.lodash.unzip(columns.map(([column, desc]) => [column, desc === 'd']))
      const search = GlobalSearch.getActiveGridSearch(this.params.scope, this.params.targetDocTy)
      search?.setSortSet({ sortBy, sortDesc }, doNotApply, this.document?.id)
      if (doNotApply) {
        search?.execute()
      }
    },
    documentToJSON () {
      return {
        cols: this.document.columns,
        docType: this.document.viewType,
        isPublic: this.document.isPublic,
        name: this.document.name,
        scope: this.params.scope ?? null
      }
    },
    async save () {
      const id = await this.savePreset('sort-sets')
      GlobalSearch.getActiveGridSearch(this.params.scope, this.params.targetDocTy)?.setSortSet(id).execute()
    }
  }
}
</script>

<style lang="stylus" scoped>
.overflow-visible
  overflow visible

.full-height
  height 100%

.align-right
  align-items right
</style>
